<template lang="pug">
  transition(name="slide-fade")
    div
      loading(:active.sync="busy", :is-full-page="true")
      form(@submit.prevent="submit", v-if="publisher")

        .position-relative
          .buttons.pos-right
            router-link.btn.btn-default(:to="{name:'publisher-list'}")
              i.la.la-angle-left
              | Back
            button.btn.btn-primary.ml-2(type="submit", :disabled="!!publisher.reserved") Save
              i.la.la-fw.la-save

          b-tabs(content-class="mt-3")
            b-tab(title="Main")

              .row
                .col-sm-6
                  .widget
                    .widget-header
                      h1.title {{ publisher.id ? 'Edit publisher' : 'Create publisher' }}
                    .widget-body
                      .row
                        .col-sm-6(v-if="publisher.id")
                          .form-group
                            label ID
                            input.form-control(type="text", disabled="disabled", :value="publisher.id")
                        .col-sm-6
                          .form-group
                            // label Account Status
                            label &nbsp;
                            div
                              toggle-button(v-model="publisher.status",
                                :disabled="!USER.permissions['publishers::EDIT']"
                                :labels="{checked: 'Active', unchecked: 'Inactive'}",
                                :width="120", :height="32", :font-size="12")

                      .row
                        .col-sm-6
                          .form-group
                            label Name
                            input.form-control(type="text", v-model="publisher.name", :readonly="!USER.permissions['publishers::EDIT']")
                        .col-sm-6
                          .form-group
                            label Media Type
                            select.form-control(v-model="publisher.media_type")
                              option(v-for="o in mediaTypeOptions" :value="o.v") {{ o.t }}

                  .widget(v-if="USER.trackingDomains.length > 0")
                    .widget-header
                      h1.title Tracking
                    .widget-body
                      label Custom Tracking Domain
                      select.form-control(v-model="publisher.custom_tracking_domain")
                        option(v-for="o in USER.trackingDomains" :value="o") {{ o ? o : 'Default' }}

                  //.widget
                    .widget-header
                      h2.title Automatic Connections
                    .widget-body
                      div.toggle-wrapper
                        toggle-button(v-model="publisher.auto_placements", :width="40", :height="20", :font-size="14")
                        span.lbl Automatic Campaigns?
                      div.toggle-wrapper
                        toggle-button(v-model="publisher.auto_clean_feed", :width="40", :height="20", :font-size="14")
                        span.lbl Auto-Clean feeds?
                      div.toggle-wrapper
                        toggle-button(v-model="publisher.all_demand", :width="40", :height="20", :font-size="14")
                        span.lbl Enable all demand

                      table.table.table-links
                        thead
                          tr
                            th Advertiser
                            th.numeric Risk
                            th.numeric Payout
                            th Payout models
                            th &nbsp;
                        tbody
                          tr(v-for="(a,i) in connectedAdvertisers")
                            td {{ a.name }}
                            td
                              .input-group.input-group-sm
                                input.form-control(type="text", placeholder="90", v-model.number="a.default_risk_management")
                                div.input-group-append
                                  span.input-group-text %
                              .form-text 0 = default
                            td
                              .input-group.input-group-sm
                                input.form-control(type="text", placeholder="90", v-model.number="a.default_payout_percent")
                                div.input-group-append
                                  span.input-group-text %
                              .form-text 0 = default
                            td
                              .toggle-wrapper(v-for="p in a.payout_models")
                                toggle-button(v-model="p.selected", :labels="{checked: p.v, unchecked: p.v}", :width="60", :height="24", :font-size="12")

                                //label.checkbox
                                  input(type="checkbox", v-model="p.selected")
                                  | {{ p.v }}
                            td
                              button.btn.btn-secondary(type="button", @click="removeAdvertiser(i)")
                                i.la.la-times
                        tfoot
                          tr
                            td
                              .form-group(style="width: 200px")
                                v-select(:options="advertiserOptions", v-model.number="selectedAdvertiserId", label="t", :reduce="o => o.v")
                            td(colspan="5")
                              button.btn.btn-secondary(type="button", @click="addAdvertiser()") Add

                .col-sm-6
                  // (v-if="USER.permissions['publishers::UPDATE_ACCOUNT_CRED']")
                  //.widget
                    .widget-header
                      h2.title Login Information
                    .widget-body
                      .row
                        .col-sm-6
                          .form-group
                            label Email
                            input.form-control(type="email", placeholder="john.doe@gmail.com", v-model="publisher.email")
                        .col-sm-6
                          .form-group
                            label Password
                            input.form-control(type="password", placeholder="Password", v-model="publisher.password")
                            .form-text(v.if="publisher.id") Leave blank to keep current value
                      .row
                        .col-sm-12
                          label &nbsp;
                          div.toggle-wrapper
                            toggle-button(v-model="publisher.require_terms", :width="40", :height="20", :font-size="14")
                            span.lbl Require to approve T&C

                  .widget(v-if="USER.permissions['publishers::EDIT']")
                    .widget-header
                      h2.title Defaults and Caps
                    .widget-body
                      .row
                        .col-sm-3
                          .form-group
                            label Default payout percent
                            .input-group
                              input.form-control(type="text", placeholder="Eg. 90", v-model="publisher.default_placement_payout")
                              div.input-group-append
                                span.input-group-text %
                      .row
                        .col-sm-6
                          .form-group
                            label Default placement cap
                            input.form-control(type="text", placeholder="2000000", v-model="publisher.default_placement_cap")
                        .col-sm-6
                          .form-group
                            label Default subsource cap
                            input.form-control(type="text", placeholder="200000", v-model="publisher.default_subsource_cap")

                      .row
                        .col-sm-6
                          // b-form-checkbox(v-model="publisher.blank_fallback") Blank page on fallback/click block
                          b-form-checkbox(v-model="publisher.ignore_freq_cap") Ignore Frequency Cap

                      //.row(v-if="USER.special_account_features")
                        .col-sm-6
                          .form-group
                            label Send % of pixel traffic to fallback
                            .input-group(style="width: 90px;")
                              input.form-control(style="padding: 7px 4px; text-align: center;", type="number", v-model.number="publisher.default_smartlink_pixel", max="100", min="-1")
                              .input-group-append
                                .input-group-text %


                  //.widget
                    .widget-header
                      h2.title Blocked Advertisers
                    .widget-body
                      multiselect(:multiple="true", :options="advertiserOptions", v-model="blockedAdvertisers",
                        track-by="v", label="t", deselect-label="", select-label="")


                  //.widget
                    .widget-header
                      h2.title S2S Support
                      //div
                    .widget-body
                      .form-group
                        div
                          toggle-button(v-model="publisher.s2s_support", :labels="{checked: 'Active', unchecked: 'Inactive'}", :width="100", :height="32", :font-size="12")
                          .form-text When active - IP, UA, Referer & Lang will be determined by macros sent by the publisher

                  .widget
                    .widget-body
                      .form-group
                        label Comments (Internal use)
                        textarea.form-control(rows="3", placeholder="Comments (Internal use)", v-model="publisher.comments")

            b-tab(title="Integration & Postbacks")

              .widget(v-if="USER.permissions['publishers::UPDATE_POSTBACK']")
                .widget-header
                  h2.title Postbacks
                .widget-body
                  //.row(v-if="USER.permissions['publishers::UPDATE_POSTBACK']")
                    .col-sm-12
                      .form-group
                        label Conversion Postback URL
                        input.form-control(type="text", placeholder="http://...", v-model="publisher.postback_url")
                      .form-group
                        label Event Postback URL
                        input.form-control(type="text", placeholder="http://...", v-model="publisher.postback_event_url")

                  p.clearfix &nbsp;
                  .row
                    .col-md-8
                      table.table.postback-table
                        thead
                          tr
                            th Postback
                            th(style="width: 140px;") Type
                            th(style="width: 60px;") Active
                            th.text-center(style="width: 50px;")
                              a(href="javascript:void(0)", @click="addPostback()")
                                i.la.la-plus
                        tbody
                          tr(v-if="publisher.postbacks.length === 0")
                            td(colspan="4") No postbacks were defined
                          tr(v-for="(p, i) in publisher.postbacks", :class="{'row-selected': editedPostbackIdx === i}")
                            td
                              // input.form-control(type="text", v-model="p.url", placeholder="https://...", :disabled="!publisher.advanced_privacy_support && apPostbackTypes.includes(p.type)")
                              input.form-control(type="text", v-model="p.url", placeholder="https://...", disabled="disabled")
                            td
                              select.form-control(v-model="p.type", style="width: 155px;", disabled="disabled")
                                option(v-for="o in postbackOptions" :value="o.v") {{ o.t }}
                            td.text-center
                              b-form-checkbox(v-model="p.enabled", :disabled="!publisher.advanced_privacy_support && apPostbackTypes.includes(p.type)")
                            td.actions
                              button.btn.btn-secondary(type="button", @click="editPostback(i)", :disabled="!!editedPostback")
                                i.la.la-edit
                              button.btn.btn-danger(type="button", @click="publisher.postbacks.splice(i, 1)", :disabled="!!editedPostback")
                                i.la.la-trash

                      .edit-postback-form(v-if="editedPostback")
                        h4 Postback Settings
                        .form-group
                          label Type
                          select.form-control(v-model="editedPostback.type", style="width: 155px;", @change="onPostbackTypeChange(editedPostback)")
                            option(v-for="o in postbackOptions" :value="o.v") {{ o.t }}
                        .form-group
                          label URL
                          textarea.form-control(v-model="editedPostback.url", placeholder="https://...")
                        .form-group
                          button.btn.btn-secondary.mr-1(type="button", @click="cancelPostbackEdit()")
                            | Cancel
                          button.btn.btn-primary.mr-1(type="button", @click="updatePostbackChanges()", :disabled="!editedPostback.url")
                            | {{ editedPostbackIdx >= 0 ? 'Update' : 'Add' }}
                            i.la.la-save

                        .url-validator.mb-1(v-if="urlValidator.visible")
                          p.clearfix
                          .message
                            i.la.mr-1(:class="{'la-check': urlValidator.valid, 'la-warning': !urlValidator.valid}")
                            span(style="font-weight: 600") {{ urlValidator.message }} !
                          .url(style="background-color: #ffc10724")
                            text-highlight(:queries="urlValidator.highlight", :highlightStyle="urlValidator.style") {{ editedPostback.url }}

                      .ml-3
                        .mb-1.fs-6(v-if="showInstallPostbackWarning")
                          i.la.la-warning 
                          span No install postback defined or it's disabled
                        .mb-1.fs-6(v-if="showEventPostbackWarning")
                          i.la.la-warning
                          span No event postback defined or it's disabled
                        .mb-1.fs-6(v-if="showApPostbackWarning")
                          i.la.la-warning
                          span No AP postback defined or it's disabled

                    .col-md-4
                      div.macros-part(v-if="availableMacros")
                        table.macros-list.table
                          thead
                            tr
                              th Macro
                              th Description
                              th Aliases
                          tbody
                            tr(v-for="m in availableMacros")
                              td {{ '{' + m.param + '}' }}
                              td {{ m.description }}
                              td
                                span(v-if="m.aliases  && m.aliases.length")
                                  span {{ m.aliases.join(', ') }}
                                  //span(v-for="a in m.aliases") {{  a }},

                  p.clearfix &nbsp;

                  .form-group
                    label Support Advanced Privacy postback
                    div
                      toggle-button(v-model="publisher.advanced_privacy_support", @change="onAdvancedPrivacySupportChange",
                        :labels="{checked: 'Active', unchecked: 'Inactive'}", :width="100", :height="32", :font-size="12")
                      .form-text If not supported, imitate original click attribution
                  .form-group
                    label Report only payable installs
                    div
                      toggle-button(v-model="publisher.report_payable_installs_only",
                        :labels="{checked: 'Active', unchecked: 'Inactive'}", :width="100", :height="32", :font-size="12")
                    label Report only payable events
                    div
                      toggle-button(v-model="publisher.report_payable_events_only",
                        :labels="{checked: 'Active', unchecked: 'Inactive'}", :width="100", :height="32", :font-size="12")
                  .form-group
                    label Show only payable events in API
                    div
                      toggle-button(v-model="publisher.show_payable_only",
                        :labels="{checked: 'Active', unchecked: 'Inactive'}", :width="100", :height="32", :font-size="12")
                  .form-group
                    label Fetch API Statistics Enabled
                    div
                      toggle-button(v-model="publisher.api_stats_enabled",
                        :labels="{checked: 'Active', unchecked: 'Inactive'}", :width="100", :height="32", :font-size="12")

              p.clearfix &nbsp;

              .widget
                .widget-header
                  h2.title Tracking Link Settings
                .widget-body
                  p Allowed characters in tracking link parameters are: a-z, A-Z, 0-9, -, _. Should be enclosed in curly braces.
                  .row
                    .col-lg-3.col-md-4.col-sm-6
                      validated-input(label="aff_clickid", :value="getUrlParam('aff_clickid')", @input="updateUrlParam('aff_clickid', $event)", :is-valid="overrideTrackingParamsValidityMap.aff_clickid", :error-message="overrideTrackingParamsValidityErrorMessageMap.aff_clickid")
                    .col-lg-3.col-md-4.col-sm-6
                      validated-input(label="sub1", :value="getUrlParam('sub1')", @input="updateUrlParam('sub1', $event)", :is-valid="overrideTrackingParamsValidityMap.sub1", :error-message="overrideTrackingParamsValidityErrorMessageMap.sub1") 
                    .col-lg-3.col-md-4.col-sm-6
                      validated-input(label="sub2", :value="getUrlParam('sub2')", @input="updateUrlParam('sub2', $event)", :is-valid="overrideTrackingParamsValidityMap.sub2", :error-message="overrideTrackingParamsValidityErrorMessageMap.sub2")
                    .col-lg-3.col-md-4.col-sm-6
                      validated-input(label="sub3", :value="getUrlParam('sub3')", @input="updateUrlParam('sub3', $event)", :is-valid="overrideTrackingParamsValidityMap.sub3", :error-message="overrideTrackingParamsValidityErrorMessageMap.sub3")
                    .col-lg-3.col-md-4.col-sm-6
                      validated-input(label="app_name", :value="getUrlParam('app_name')", @input="updateUrlParam('app_name', $event)", :is-valid="overrideTrackingParamsValidityMap.app_name", :error-message="overrideTrackingParamsValidityErrorMessageMap.app_name")
                    .col-lg-3.col-md-4.col-sm-6
                      validated-input(label="bundle_id", :value="getUrlParam('bundle_id')", @input="updateUrlParam('bundle_id', $event)", :is-valid="overrideTrackingParamsValidityMap.bundle_id", :error-message="overrideTrackingParamsValidityErrorMessageMap.bundle_id")
                    .col-lg-3.col-md-4.col-sm-6(v-b-tooltip.hover.bottom, title="iOS only")
                      validated-input(label="idfa", :value="getUrlParam('idfa')", @input="updateUrlParam('idfa', $event)", :is-valid="overrideTrackingParamsValidityMap.idfa", :error-message="overrideTrackingParamsValidityErrorMessageMap.idfa")
                    .col-lg-3.col-md-4.col-sm-6(v-b-tooltip.hover.bottom, title="Android only")
                      validated-input(label="gaid", :value="getUrlParam('gaid')", @input="updateUrlParam('gaid', $event)", :is-valid="overrideTrackingParamsValidityMap.gaid", :error-message="overrideTrackingParamsValidityErrorMessageMap.gaid")

              .widget(v-if="USER.permissions['publishers::RISK']")
                .widget-header
                  h2.title Risk Management
                .widget-body
                  .form-group
                    label Auto Report
                    div
                      toggle-button(v-model="publisher.auto_report", :labels="{checked: 'Active', unchecked: 'Inactive'}", :width="100", :height="32", :font-size="12")

                  .row
                    .col-sm-4
                      .form-group
                        label Full Transparency - Report everything
                        div
                          toggle-button(v-model="publisher.full_transparency", :labels="{checked: 'Active', unchecked: 'Inactive'}", :width="100", :height="32", :font-size="12")
                    .col-sm-4
                      .form-group
                        label Risk
                        .input-group
                          input.form-control(type="text", placeholder="100", v-model.number="publisher.auto_report_risk", :disabled="publisher.full_transparency")
                          div.input-group-append
                            span.input-group-text %
                    .col-sm-4
                      .form-group
                        label Manual report threshold
                        input.form-control(type="text", placeholder="100", v-model.number="publisher.manual_report_threshold")
                        p.help-block Don't auto-report if payout is above this threshold

            b-tab(title="Users", v-if="USER.permissions.admin_only && publisher && publisher.id")
              .widget
                .widget-header
                  h1.title Users
                  router-link.btn.btn-success(:to="{name:'user-add', query:{ publisher_id: publisher.id }}")
                    i.la.la-plus
                    | Add User
                .widget-body
                  .form-inline.mb-2
                    .form-group.mr-1
                      select.form-control(v-model="userFilters.status")
                        option(v-for="o in userStatusOptions", :value="o.v") {{ o.t }}
                    .form-group
                      button.btn.btn-primary(type="button", @click="loadUsers()") Go

                  .ov-table-wrapper
                    table.table.table-bordered
                      thead
                        tr
                          th ID
                          th Active
                          th Name
                          th Email
                          th Joined
                          th Actions
                      tbody
                        tr(v-if="!users.length")
                          td(colspan="6") No matching records were found
                        tr(v-for="r in users", v-bind:key="r.id")
                          td {{ r.id }}
                          td
                            span(v-if="r.status===0")
                              i.la.la-times
                            span(v-if="r.status===1")
                              i.la.la-check
                          td {{ r.name }}
                          td {{ r.email }}
                          td {{ r.created_at }}
                          td.actions
                            router-link.btn.btn-sm.btn-secondary.mr-1(:to="{name:'user-edit', params: {id:r.id}}")
                              i.la.la-pencil

            b-tab
              template(#title)
                | Placement Defaults
              div.toggle-wrapper
                toggle-button(v-model="placementDefaultsEnabled", :labels="{checked: 'Custom', unchecked: 'Global'}", :width="100", :height="32", :font-size="12")
                span.lbl Custom Placement Defaults
                  i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="When enabled - new placements of this publishers will use these settings instead of the Global Placement Defaults")

              p.clearfix
              placement-defaults(v-if="publisher.placement_defaults", :placement="publisher.placement_defaults")


            b-tab(title="Activity Log", v-if="publisher.id")
              .widget
                .widget-body
                  activity-log-table(type="publisher", :id="publisher.id")



</template>
<style lang="scss">
table.postback-table {
  tbody {
    tr {
      &.row-selected {
        background: #ffc1071f;
      }

      td {
        &.actions {
          padding: 6px;
          white-space: nowrap;

          .btn {
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            padding: 0;
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin: 1px;

            font-size: 16px;

            &.btn-action-default {
              background: #aaa;
              color: #fff;
            }

            &.btn-action-info {
              background: #00aaaa;
              color: #fff;
            }

            &.btn-action-danger {
              background: #d6d6d6;
              color: #a00;
            }
          }
        }
      }
    }
  }
}

.edit-postback-form {
  background: #ffc1071f;
  border: 1px dashed #aaa;
  padding: 15px;
  border-radius: 5px;
}
</style>
<script>
import Vue from 'vue';
import moment from 'moment';

export default {
  name: 'PublisherForm',
  async beforeRouteEnter(to, from, next) {
    let advertiserOptions = await Vue.ovData.advertiser.getOptions(true);
    let users = [];
    let publisher = {};
    if (to.name === 'publisher-edit') {
      let entityId = +to.params.id;
      try {
        publisher = await Vue.ovData.publisher.get(entityId, true);
        users = await Vue.ovData.user.getPublisherUsers({
          publisherId: entityId,
        });
      } catch (e) {
        return next(false);
      }
    } else {
      publisher = Vue.ovData.publisher.newInstance();
    }
    Vue.ovData.publisher._booleans.forEach((f) => {
      publisher[f] = !!publisher[f];
    });

    let macros = [];
    let rejectionMacros = [];
    try {
      let systemMacros = await Vue.ovData.general.getUrlMacros();
      macros = systemMacros.PUBLISHER_POSTBACK_MACROS;
      rejectionMacros = systemMacros.PUBLISHER_REJECTION_POSTBACK_MACROS;
    } catch (e) {
      console.error(e);
    }

    next((vm) => {
      vm.advertiserOptions = advertiserOptions;
      vm.publisher = publisher;
      vm.urlMacros = macros;
      vm.rejectionMacros = rejectionMacros;
      vm.users = users;
      vm.loadConnectedAdvertisers();

      // if (publisher.blocked_advertisers.length) {
      // 	let blockedAdvertisersHash = {};
      // 	let blockedAdvertisers = [];
      // 	publisher.blocked_advertisers.forEach(p => {
      // 		blockedAdvertisersHash[p] = true;
      // 	});
      // 	advertiserOptions.forEach(o => {
      // 		if (blockedAdvertisersHash[o.v]){
      // 			blockedAdvertisers.push(o);
      // 		}
      // 	});
      // 	vm.blockedAdvertisers = blockedAdvertisers;
      // }

      let entityId = +to.params.id;
      if (entityId) {
        vm.graphPerformance = {
          metrics: [
            'revenue',
            'cost',
            'profit',
            'profit_percent',
            'clicks',
            'installs',
            'conversions',
            'reported_conversions',
            'events',
            'reject_events',
          ],
          transformData: {
            metrics: {
              clicks: function (v) {
                return Math.round(v / 1000);
              },
            },
          },
          transformLabel: {
            metrics: {
              clicks: function (v) {
                return v + ' (k)';
              },
            },
          },
          dimensions: [],
          group_timeframe: 'day',
          date_start: moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm'),
          date_end: moment().format('YYYY-MM-DD HH:mm'),
          sort: ['revenue', 'desc'],
          limit: 10,
          filters: {
            publisher_id: [entityId],
          },
        };
      }

      return vm;
    });
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
    availableMacros() {
      let rejectionPB =
        this.editedPostback &&
        (this.editedPostback.type === 'reject' || this.editedPostback.type === 'ap:reject');
      return rejectionPB ? this.rejectionMacros : this.urlMacros;
    },
    placementDefaultsEnabled: {
      get() {
        return this.publisher && !!this.publisher.placement_defaults;
      },
      set() {
        if (this.publisher.placement_defaults) {
          // this.publisher.placement_defaults = null;
          Vue.set(this.publisher, 'placement_defaults', null);
        } else {
          let emptyPlacement = Vue.ovData.placement.newInstance();
          Vue.set(this.publisher, 'placement_defaults', emptyPlacement);
        }
      },
    },
    showEventPostbackWarning() {
      const eventPostback = this.publisher.postbacks.find((p) => p.type === 'event');
      return !eventPostback || !eventPostback.enabled;
    },
    showInstallPostbackWarning() {
      const installPostback = this.publisher.postbacks.find((p) => p.type === 'conversion');
      return !installPostback || !installPostback.enabled;
    },
    showApPostbackWarning() {
      const apEventPostback = this.publisher.postbacks.find((p) => p.type === 'ap:event');
      const apInstallPostback = this.publisher.postbacks.find((p) => p.type === 'ap:conversion');
      const noEvent = !apEventPostback || !apEventPostback.enabled;
      const noInstall = !apInstallPostback || !apInstallPostback.enabled;
      const apActive = this.publisher.advanced_privacy_support;
      return (noEvent || noInstall) && apActive;
    },
    mediaTypeOptions() {
      let options = [
        { v: 'dsp', t: 'DSP' },
        { v: 'rewarded', t: 'Rewarded' },
      ];
      if (this.$store.state.user && this.$store.state.user.config.mediaBuyingSync) {
        options.push({ v: 'media_buying', t: 'Media Buying' });
      }
      return options;
    },
  },
  data() {
    return {
      busy: false,
      // blockedAdvertisers: [],
      datepickerConf: {
        format: 'DD/MM/YYYY',
      },
      publisher: null,
      connectedAdvertisers: [],
      advertiserOptions: [],
      selectedAdvertiserId: 0,
      postbackOptions: [
        { v: 'event', t: 'Event', ap: false },
        { v: 'conversion', t: 'Install', ap: false },
        { v: 'reject', t: 'Reject', ap: false },
        { v: 'ap:event', t: 'AP Event', ap: true },
        { v: 'ap:conversion', t: 'AP Install', ap: true },
        { v: 'ap:reject', t: 'AP Reject', ap: true },
      ],
      apPostbackTypes: [],
      urlMacros: null,
      rejectionMacros: null,
      graphPerformance: null,
      editedPostback: null,
      editedPostbackIdx: -1,
      urlValidator: {
        highlight: '',
        style: '',
        valid: true,
        visible: false,
      },
      users: [],
      overrideTrackingParamsValidityMap: {
        aff_clickid: true,
        idfa: true,
        gaid: true,
        sub1: true,
        sub2: true,
        sub3: true,
        app_name: true,
        bundle_id: true,
      },
      overrideTrackingParamsValidityErrorMessageMap: {
        aff_clickid: '',
        idfa: '',
        gaid: '',
        sub1: '',
        sub2: '',
        sub3: '',
        app_name: '',
        bundle_id: '',
      },
      userFilters: {
        status: null,
      },
      userStatusOptions: [
        { v: null, t: 'All' },
        { v: 1, t: 'Active' },
        { v: 0, t: 'Inactive' },
      ],
    };
  },
  created() {
    this.apPostbackTypes = this.postbackOptions.filter((p) => p.ap).map((p) => p.v);
  },
  mounted() {
    this.revalidateUrlParams();
  },
  methods: {
    getPayoutModels() {
      return [
        { v: 'CPA', selected: false },
        { v: 'CPI', selected: false },
        { v: 'CPR', selected: false },
        { v: 'CPC', selected: false },
        { v: 'CPM', selected: false },
        { v: 'CPS', selected: false },
      ];
    },

    /** @param {string} key @returns {string} */
    getUrlParam(key) {
      const override = this.publisher.override_tracking_params[key];
      if (override === undefined && key === 'aff_clickid') return '{clickid}';
      if (override === undefined) return '';
      return override;
    },
    /** @param {string} key @param {string} value */
    updateUrlParam(key, value) {
      this.publisher.override_tracking_params[key] = value;
      this.revalidateUrlParams();
    },
    /** @param {string} key */
    validateUrlParam(key) {
      const regex = new RegExp(/(^{[a-zA-Z0-9_-]+}$)|(^$)/);
      const value = this.getUrlParam(key);
      if (key === 'aff_clickid' && !value) {
        this.overrideTrackingParamsValidityErrorMessageMap[key] = 'Affiliate Click ID is mandatory';
        return false;
      }

      const result = regex.test(value);
      this.overrideTrackingParamsValidityErrorMessageMap[key] = result ? '' : 'Invalid URL parameter';
      return result;
    },
    revalidateUrlParams() {
      this.overrideTrackingParamsValidityMap = {
        aff_clickid: this.validateUrlParam('aff_clickid'),
        idfa: this.validateUrlParam('idfa'),
        gaid: this.validateUrlParam('gaid'),
        sub1: this.validateUrlParam('sub1'),
        sub2: this.validateUrlParam('sub2'),
        sub3: this.validateUrlParam('sub3'),
        app_name: this.validateUrlParam('app_name'),
        bundle_id: this.validateUrlParam('bundle_id'),
      };
    },

    async loadConnectedAdvertisers() {
      if (this.publisher.id) {
        let resp = await this.$ovReq.get('publisher/getConnectedAdvertisers/' + this.publisher.id);
        if (resp.records) {
          resp.records.forEach((r) => {
            let o = {
              id: r.id,
              name: r.name,
              default_risk_management: r.default_risk_management,
              default_payout_percent: r.default_payout_percent,
              payout_models: this.getPayoutModels(),
            };
            o.payout_models.forEach((p) => {
              if (r.payout_models.indexOf(p.v) > -1) {
                p.selected = true;
              }
            });
            this.connectedAdvertisers.push(o);
          });
        }
      }
    },

    addPostback() {
      // this.publisher.postbacks.push({
      // 	url: '',
      // 	type: 'conversion',
      // 	enabled: true
      // });
      this.editedPostback = {
        url: '',
        type: 'conversion',
        enabled: true,
      };
    },

    editPostback(idx) {
      this.editedPostbackIdx = idx;
      this.editedPostback = Object.assign({}, this.publisher.postbacks[idx]);
    },
    updatePostbackChanges() {
      this.validateUrl(this.editedPostback.url);
      if (this.urlValidator.valid) {
        if (this.editedPostbackIdx >= 0) {
          this.publisher.postbacks[this.editedPostbackIdx].url = this.editedPostback.url;
        } else {
          this.publisher.postbacks.push(this.editedPostback);
        }
        this.editedPostbackIdx = -1;
        this.editedPostback = null;
      }
    },
    cancelPostbackEdit() {
      this.editedPostbackIdx = -1;
      this.editedPostback = null;
    },

    addAdvertiser() {
      if (this.selectedAdvertiserId) {
        let selectedAdvertiserName = (() => {
          for (let i = 0; i < this.advertiserOptions.length; i++) {
            if (this.advertiserOptions[i].v === this.selectedAdvertiserId) {
              return this.advertiserOptions[i].t;
            }
          }
          return '';
        })();
        this.connectedAdvertisers.push({
          id: this.selectedAdvertiserId,
          name: selectedAdvertiserName,
          payout_models: this.getPayoutModels(),
        });
        this.selectedAdvertiserId = 0;
      }
    },

    removeAdvertiser(index) {
      this.connectedAdvertisers.splice(index, 1);
    },

    async submit() {
      if (this.busy) {
        return;
      }
      if (this.publisher.reserved) {
        return this.$ovNotify.error('You are not authorized to perform this action');
      }

      const allTrackingParamsValid = Object.values(this.overrideTrackingParamsValidityMap).every((v) => v);
      if (!allTrackingParamsValid) {
        return this.$ovNotify.error('Please check all tracking link parameters');
      }

      this.busy = true;
      let data = Vue.util.extend({}, this.publisher);
      Vue.ovData.publisher._booleans.forEach((f) => {
        data[f] = data[f] ? 1 : 0;
      });

      let connectedAdvertisers = this.connectedAdvertisers
        .map((a) => {
          let o = {
            id: a.id,
            default_risk_management: a.default_risk_management,
            default_payout_percent: a.default_payout_percent,
            payout_models: [],
          };
          a.payout_models.forEach((p) => {
            if (p.selected) {
              o.payout_models.push(p.v);
            }
          });
          return o.payout_models.length > 0 ? o : null;
        })
        .filter((r) => r);
      data.advertisers = connectedAdvertisers;
      // data.blocked_advertisers = this.blockedAdvertisers.map(r => r.v);

      try {
        /*let resp = */
        await this.$ovReq.post('publisher/save', data);
        this.busy = false;
        this.$ovNotify.success('Publisher has been saved');
        this.$router.push({ name: 'publisher-list' });
      } catch (e) {
        this.$ovNotify.error(e);
        this.busy = false;
      }
    },

    onAdvancedPrivacySupportChange() {
      this.publisher.postbacks.forEach((p) => {
        if (this.apPostbackTypes.includes(p.type)) {
          Vue.set(p, 'enabled', this.publisher.advanced_privacy_support);
        }
      });
    },

    onPostbackTypeChange(pb) {
      if (!this.publisher.advanced_privacy_support) {
        Vue.set(pb, 'enabled', !this.apPostbackTypes.includes(pb.type));
      }
    },

    showUrlHelper(valid, highlight, message) {
      const invalidColor = 'rgba(220,53,69,0.6)';
      const validColor = '#40dc7e6b';
      Vue.set(this.urlValidator, 'visible', !valid);
      Vue.set(this.urlValidator, 'style', {
        'background-color': valid ? validColor : invalidColor,
      });
      Vue.set(this.urlValidator, 'valid', valid);
      Vue.set(this.urlValidator, 'highlight', highlight);
      Vue.set(this.urlValidator, 'message', message || '');
    },

    validateUrl: function () {
      let url = this.editedPostback.url.trim();
      if (!url) {
        return this.showUrlHelper(false, null, 'Url is mandatory');
      }

      /** check for multiple "?" */
      let regMatch = url.match(new RegExp('[?]', 'g'));
      let numOfQM = regMatch ? regMatch.length : 0;
      if (numOfQM > 1) {
        return this.showUrlHelper(false, /[?]/, 'Invalid url');
      }

      /** check for unclosed parentheses */
      let counter = 0,
        validParentheses = true;
      for (let i = 0; i < url.length; i++) {
        if (url[i] === '{') {
          counter++;
        } else if (url[i] === '}') {
          counter--;
        }
        if (counter < 0) {
          validParentheses = false;
        }
      }
      if (!validParentheses || counter !== 0) {
        return this.showUrlHelper(false, /[{,}]/, 'Unclosed parentheses');
      }

      /** check input macros are matching the supplied macros */
      const arrayOfMacros = url.match(/{[^}{]*}/g);
      let invalidMacros = [];
      if (arrayOfMacros && arrayOfMacros.length) {
        for (let macro of arrayOfMacros) {
          let macroKey = macro.substr(1, macro.length - 2);
          let matchingMacro = this.availableMacros.find((m) => m.param === macroKey);
          if (!matchingMacro) {
            invalidMacros.push(macroKey);
          }
        }
      }
      if (invalidMacros.length) {
        return this.showUrlHelper(
          false,
          invalidMacros.map((m) => `{${m}}`),
          'Invalid macro',
        );
      }

      this.showUrlHelper(true, /{[^}{]*}/g, 'Link is valid');
    },

    async loadUsers() {
      this.busy = true;
      try {
        this.users = await Vue.ovData.user.getPublisherUsers({
          publisherId: this.publisher.id,
          status: this.userFilters.status
        });
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.busy = false;
    },
  },
};
</script>
